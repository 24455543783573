import React from 'react';

function SortDownSvg() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4903_34477)">
        <mask
          id="mask0_4903_34477"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="3"
          width="6"
          height="10"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.14645 5.67618C4.95118 5.87364 4.95118 6.19378 5.14645 6.39123C5.34171 6.58869 5.65829 6.58869 5.85355 6.39123L8 4.22067L10.1464 6.39123C10.3417 6.58869 10.6583 6.58869 10.8536 6.39123C11.0488 6.19378 11.0488 5.87364 10.8536 5.67618L8.35355 3.14809C8.25978 3.05327 8.13261 3 8 3C7.86739 3 7.74021 3.05327 7.64645 3.14809L5.14645 5.67618ZM10.8536 10.3238C11.0488 10.1264 11.0488 9.80622 10.8536 9.60877C10.6583 9.41131 10.3417 9.41131 10.1464 9.60877L8 11.7793L5.85355 9.60877C5.65829 9.41131 5.34171 9.41131 5.14645 9.60877C4.95119 9.80622 4.95119 10.1264 5.14645 10.3238L7.64645 12.8519C7.84171 13.0494 8.15829 13.0494 8.35355 12.8519L10.8536 10.3238Z"
            fill="#0891B2"
          />
        </mask>
        <g mask="url(#mask0_4903_34477)">
          <rect x="1" y="8" width="16" height="16" fill="black" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4903_34477">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SortDownSvg;
