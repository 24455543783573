export const SIZES = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  BASE: 'bs',
};

export const CONTROL_SIZES = {
  [SIZES.XS]: 7,
  [SIZES.SM]: 9,
  [SIZES.BASE]: 10,
  [SIZES.MD]: 11,
  [SIZES.LG]: 14,
};

export const LAYOUT = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  INLINE: 'inline',
};

export const DIRECTIONS = {
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left',
};

export const SELECTION_MODES = {
  YEAR: 'year',
  MONTH: 'month',
  DAY: 'day',
};

export const PICKER_VIEWS = {
  YEAR: 'year',
  MONTH: 'month',
  DATE: 'date',
};

export const STATUS = {
  DANGER: 'danger',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const STEPS_STATUS = {
  COMPLETE: 'complete',
  PENDING: 'pending',
  IN_PROGRESS: 'in-progress',
  ERROR: 'error',
};

export const PLACEMENT = {
  TOP_START: 'top-start',
  TOP_CENTER: 'top-center',
  TOP_END: 'top-end',
  BOTTOM_START: 'bottom-start',
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_END: 'bottom-end',
  MIDDLE_START_TOP: 'middle-start-top',
  MIDDLE_START_BOTTOM: 'middle-start-bottom',
  MIDDLE_END_TOP: 'middle-end-top',
  MIDDLE_END_BOTTOM: 'middle-end-bottom',
};

export const DROPDOWN_ITEM_TYPE = {
  DEFAULT: 'default',
  HEADER: 'header',
  DIVIDER: 'divider',
  CUSTOM: 'custom',
};

export const DAY_DURATION = 86400000;

export const OPTION_PLAT_TYPE = [
  {
    value: 0,
    label: 'Hitam',
  },
  {
    value: 1,
    label: 'Kuning',
  },
];

export const OPTION_CUSTOMER_TYPE = [
  {
    value: 0,
    label: 'Corporate',
  },
  {
    value: 1,
    label: 'Individual',
  },
];

export const OPTION_STATUS_MASTER_DATA = [
  {
    value: 1,
    label: 'Active',
  },
  {
    value: 0,
    label: 'Inactive',
  },
];

export const OPTION_STATUS_ORDER = [
  {
    label: 'Completed',
    value: 3,
  },
  {
    value: 2,
    label: 'Canceled',
  },
  {
    value: 1,
    label: 'Pending',
  },
];

export const OPTION_GENDER = [
  {
    value: 0,
    label: 'Female',
  },
  {
    value: 1,
    label: 'Male',
  },
];

export const OPTION_CATEGORY_MASTER_DATA = [
  {
    value: 1,
    label: 'External',
  },
  {
    value: 0,
    label: 'Internal',
  },
];

export const OPTION_TIME_RANGE_MASTER_DATA = [
  {
    value: 1,
    label: 'Last 7 days ago',
  },
  {
    value: 2,
    label: 'Last 14 days ago',
  },
  {
    value: 3,
    label: 'Last 1 month ago',
  },
  {
    value: 4,
    label: 'Last 3 months ago',
  },
  {
    value: 5,
    label: 'Last 6 months ago',
  },
  {
    value: 6,
    label: 'Last year ago',
  },
];

export const OPTION_STATUS_SALES_DASHBOARD = [
  {
    value: 2,
    label: 'Canceled',
  },
  {
    value: 1,
    label: 'Completed',
  },
  {
    value: 0,
    label: 'Pending',
  },
];

export const OPTION_SALER_OVERVIEW_DASHBOARD = [
  {
    value: 'today',
    label: 'Today',
  },
  {
    value: 'last_7_days',
    label: 'Last 7 Days',
  },
  {
    value: 'last_30_days',
    label: 'Last 30 Days',
  },
  {
    value: 'last_6_months',
    label: 'Last 6 Months',
  },
  {
    value: 'last_year',
    label: 'Last Year',
  },
  {
    value: 'last_5_year',
    label: 'Last 5 Years',
  },
  {
    value: 'custom',
    label: 'Custom',
  },
];

export const OPTION_STATUS_PLAN_DASHBOARD = [
  {
    value: 'today',
    label: 'Today',
  },
  {
    value: 'month',
    label: 'This Month',
  },
];

export const OPTION_CUSTOMER_STATUS = [
  {
    value: 1,
    label: 'Safe',
  },
  {
    value: 2,
    label: 'Warning',
  },
  {
    value: 3,
    label: 'Not Safe',
  },
];

export const OPTION_RECEIVABLES_STATUS = [
  {
    value: 1,
    label: 'Outstanding',
  },
  {
    value: 2,
    label: 'Paid',
  },
];

export const OPTION_SALES_TARGET_STATUS = [
  {
    value: 1,
    label: 'Ongoing',
  },
  {
    value: 2,
    label: 'Achieved',
  },
  {
    value: 3,
    label: 'Failed',
  },
];

export const OPTION_COMPLAINTS_STATUS = [
  {
    value: 0,
    label: 'Unresolved',
  },
  {
    value: 1,
    label: 'Resolved',
  },
];

export const OPTION_DISCOUNT_MASTER_DATA = [
  {
    value: 0,
    label: 'disable',
  },
  {
    value: 1,
    label: 'enable',
  },
];

export const OPTION_VISIT_PLANS_STATUS = [
  {
    value: 1,
    label: 'Plan',
  },
  {
    value: 2,
    label: 'Requested',
  },
  {
    value: 3,
    label: 'Confirmed',
  },
  {
    value: 4,
    label: 'Canceled',
  },
  {
    value: 5,
    label: 'Reschedule',
  },
];

export const OPTION_ACTIVITY_REPORT_STATUS = [
  {
    value: 6,
    label: 'Completed',
  },
  {
    value: 7,
    label: 'Unvisited',
  },
];

export const STATUS_VISIT_PLAN = 1;
export const STATUS_VISIT_REQUESTED = 2;
export const STATUS_VISIT_CONFIRMED = 3;
export const STATUS_VISIT_CANCELED = 4;
export const STATUS_VISIT_RESCHEDULE = 5;
export const STATUS_VISIT_COMPLETED = 6;
export const STATUS_VISIT_UNVISITED = 7;
export const STATUS_IS_VISIT_COMPLETED = 2;

export const STATUS_ACTIVITY_PLANNED = 1;
export const STATUS_ACTIVITY_ACTUAL = 2;

export const OPTION_DAYS = [
  {
    value: 'Monday',
    label: 'Monday',
  },
  {
    value: 'Tuesday',
    label: 'Tuesday',
  },
  {
    value: 'Wednesday',
    label: 'Wednesday',
  },
  {
    value: 'Thursday',
    label: 'Thursday',
  },
  {
    value: 'Friday',
    label: 'Friday',
  },
  {
    value: 'Saturday',
    label: 'Saturday',
  },
  {
    value: 'Sunday',
    label: 'Sunday',
  },
];

export const PLACEHOLDER_W_NAME = 'https://ui-avatars.com/api/?background=random&name=';

export const OPTION_MAX_VISIT_TEMPLATE = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
];

export const OPTION_WEEK = [
  {
    value: 1,
    label: "Week 1"
  },
  {
    value: 2,
    label: "Week 2"
  },
  {
    value: 3,
    label: "Week 3"
  },
  {
    value: 4,
    label: "Week 4"
  },
  {
    value: 5,
    label: "Week 5"
  },
];