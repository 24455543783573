import React from 'react';

function BreadCrumbSvg() {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.23483 10.2652C4.08839 10.1187 4.08839 9.88128 4.23483 9.73483L7.46967 6.5L4.23483 3.26516C4.08839 3.11872 4.08839 2.88128 4.23483 2.73483C4.38128 2.58839 4.61872 2.58839 4.76516 2.73483L8.26516 6.23483C8.41161 6.38128 8.41161 6.61872 8.26516 6.76516L4.76516 10.2652C4.61872 10.4116 4.38128 10.4116 4.23483 10.2652Z"
        fill="#CBD5E1"
      />
    </svg>
  );
}

export default BreadCrumbSvg;
