import React from 'react';

function SortSvg() {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_4729_15984"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="3"
        width="6"
        height="11"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.26058 6.29401C5.07313 6.48146 5.07313 6.78538 5.26058 6.97283C5.44804 7.16028 5.75196 7.16028 5.93941 6.97283L8 4.91224L10.0606 6.97283C10.248 7.16028 10.552 7.16028 10.7394 6.97283C10.9269 6.78538 10.9269 6.48146 10.7394 6.29401L8.33941 3.89401C8.24939 3.80399 8.1273 3.75342 8 3.75342C7.87269 3.75342 7.7506 3.80399 7.66059 3.89401L5.26058 6.29401ZM10.7394 10.7062C10.9269 10.5187 10.9269 10.2148 10.7394 10.0273C10.552 9.83989 10.248 9.83989 10.0606 10.0273L8 12.0879L5.93941 10.0273C5.75196 9.83989 5.44804 9.83989 5.26058 10.0273C5.07313 10.2148 5.07313 10.5187 5.26058 10.7062L7.66059 13.1062C7.84804 13.2936 8.15196 13.2936 8.33941 13.1062L10.7394 10.7062Z"
          fill="#0891B2"
        />
      </mask>
      <g mask="url(#mask0_4729_15984)">
        <rect y="0.5" width="16" height="16" fill="black" />
      </g>
    </svg>
  );
}

export default SortSvg;
