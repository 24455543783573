import React from 'react';

function CloseEyeSvg() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_4350_4710"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="-1"
        y="8"
        width="21"
        height="10"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6866 8.10135C19.9989 8.29649 20.0938 8.70779 19.8986 9.02001C19.3132 9.95666 18.6176 10.8087 17.8269 11.5508L19.4283 13.1522C19.6886 13.4125 19.6886 13.8347 19.4283 14.095C19.1679 14.3554 18.7458 14.3554 18.4855 14.095L16.8015 12.411C15.74 13.2039 14.5452 13.8162 13.245 14.2015L13.8439 16.437C13.9392 16.7927 13.7282 17.1582 13.3725 17.2535C13.0169 17.3488 12.6513 17.1377 12.556 16.7821L11.9455 14.5035C11.3165 14.6108 10.6671 14.6667 9.99997 14.6667C9.33282 14.6667 8.68338 14.6108 8.05437 14.5035L7.44392 16.7821C7.34864 17.1377 6.98309 17.3488 6.62745 17.2535C6.2718 17.1582 6.06073 16.7927 6.15601 16.437L6.75493 14.2015C5.45474 13.8162 4.25995 13.2039 3.19844 12.411L1.51442 14.095C1.25407 14.3553 0.831963 14.3553 0.571613 14.095C0.311263 13.8346 0.311263 13.4125 0.571613 13.1522L2.17299 11.5508C1.38232 10.8087 0.686701 9.95665 0.101302 9.02001C-0.0938385 8.70779 0.001076 8.29649 0.3133 8.10135C0.625524 7.90621 1.03682 8.00112 1.23197 8.31335C1.87128 9.33625 2.6519 10.2408 3.54968 10.9904C3.5641 11.0014 3.57818 11.0131 3.59186 11.0254C5.34522 12.4738 7.54043 13.3333 9.99997 13.3333C13.7438 13.3333 16.8752 11.3418 18.768 8.31335C18.9631 8.00112 19.3744 7.90621 19.6866 8.10135Z"
          fill="#0891B2"
        />
      </mask>
      <g mask="url(#mask0_4350_4710)">
        <rect width="20" height="20" fill="#013042" />
      </g>
    </svg>
  );
}

export default CloseEyeSvg;
