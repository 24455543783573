import React from 'react';

function ArrowDownSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.3897 9.56413C4.63045 9.22707 5.09887 9.149 5.43593 9.38976L12 14.0784L18.5641 9.38976C18.9011 9.149 19.3695 9.22707 19.6103 9.56413C19.8511 9.90119 19.773 10.3696 19.4359 10.6104L12.4359 15.6104C12.1752 15.7966 11.8248 15.7966 11.5641 15.6104L4.56407 10.6104C4.22701 10.3696 4.14894 9.90119 4.3897 9.56413Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ArrowDownSvg;
