import { useSelector, useDispatch } from 'react-redux';
import { initialState, setAuthority, setBranch, setUser } from 'store/auth/userSlice';
import { apiSignIn, apiSignOut, apiSignUp, apiUserMe } from 'services/AuthService';
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';
import appConfig from 'configs/app.config';
import { REDIRECT_URL_KEY } from 'constants/app.constant';
import { useNavigate } from 'react-router-dom';
import useQuery from './useQuery';

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { access_token, refresh_token, signedIn } = useSelector((state) => state.auth.session);
  const { dataUser, branch } = useSelector((state) => state.auth.user);

  const signIn = async (values) => {
    try {
      const rememberMe = values.rememberMe;
      delete values.rememberMe;
      values.platform_id = 1;

      const resp = await apiSignIn(values);
      if (resp.data.data) {
        window.localStorage.removeItem('admin');
        dispatch(
          onSignInSuccess({
            access_token: resp.data?.data?.access_token || '',
            access_token_expires_at: resp.data?.data?.access_token_expires_at || '',
            refresh_token: resp.data?.data?.refresh_token || '',
            refresh_token_expires_at: resp.data?.data?.refresh_token_expires_at || '',
            session_id: resp.data?.data?.session_id || '',
            remember_me: rememberMe || '',
          }),
        );
        dispatch(setUser(resp?.data?.data?.user));
        dispatch(setBranch(resp?.data?.data?.user?.branch?.id || ''));

        setTimeout(() => {
          navigate(appConfig.authenticatedEntryPath);
          userMe();
        }, 3000);
      }

      return {
        status: resp?.data?.status || 'success',
        message: resp?.data?.message || '',
      };
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const userMe = async () => {
    try {
      const resp = await apiUserMe();
      if (resp.data) {
        const { data } = resp;
        if (data.data) {
          const tempData = {
            ...data?.data,
            role: data?.data?.userRole.role,
          };

          dispatch(setUser(tempData));
          const arr1 = (tempData?.userPermissions || []).map((userPermission) => userPermission?.permission?.slug);
          const arr2 = (tempData?.userRole?.role?.rolePermissions || []).map((userPermission) => userPermission?.permission?.slug);
          const arr = arr1.concat(arr2);

          const arr3 = (tempData?.unCheckuserPermissions || []).map((userPermission) => userPermission?.permission?.slug);

          let permissions = arr.filter(value => !arr3.includes(value));


          dispatch(setAuthority(permissions));
        }
        return {
          status: 'success',
          message: '',
          data: data.data,
        };
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { access_token } = resp.data;
        dispatch(onSignInSuccess(access_token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: '',
                userName: 'Anonymous',
                authority: ['USER'],
                email: '',
              },
            ),
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: 'success',
          message: '',
        };
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
    window.location.reload();
  };

  const signOut = async () => {
    await apiSignOut({ refresh_token: refresh_token });
    handleSignOut();
  };

  return {
    authenticated: access_token && signedIn,
    signIn,
    signUp,
    signOut,
    dataUser,
    branch,
    access_token,
    userMe,
  };
}

export default useAuth;
