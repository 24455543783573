import React from 'react';

function LockedSvg() {
  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="94" height="94" rx="47" fill="white" />
      <mask
        id="mask0_3568_12388"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="31"
        y="28"
        width="34"
        height="40"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.6667 39.599C40.6667 37.0303 41.3771 35.1092 42.5535 33.8425C43.7154 32.5914 45.4944 31.8031 48.0041 31.8031C50.5152 31.8031 52.2917 32.5898 53.4509 33.8382C54.6246 35.1022 55.3334 37.0208 55.3334 39.5909V43.6H40.6667V39.599ZM37.7334 43.6V39.599C37.7334 36.5057 38.594 33.7955 40.4041 31.8464C42.2288 29.8817 44.8518 28.8698 48.0041 28.8698C51.1558 28.8698 53.7773 29.8789 55.6004 31.8422C57.4089 33.7897 58.2667 36.4983 58.2667 39.5909V43.6H61.2C62.8201 43.6 64.1334 44.9133 64.1334 46.5334V64.1334C64.1334 65.7534 62.8201 67.0667 61.2 67.0667H34.8C33.18 67.0667 31.8667 65.7534 31.8667 64.1334V46.5334C31.8667 44.9133 33.18 43.6 34.8 43.6H37.7334ZM34.8 46.5334H61.2V64.1334H34.8V46.5334Z"
          fill="#0891B2"
        />
      </mask>
      <g mask="url(#mask0_3568_12388)">
        <rect x="26" y="26" width="44" height="44" fill="#013042" />
      </g>
      <rect x="1" y="1" width="94" height="94" rx="47" stroke="#013042" strokeWidth="2" />
    </svg>
  );
}

export default LockedSvg;
