import React from 'react';

const LogoMenuSvg = (props) => {
  return (
    <svg width="82" height="36" viewBox="0 0 82 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0428 9.41288C20.894 9.40823 15.7452 9.40365 10.5964 9.399C12.6443 7.33372 14.6937 5.26933 16.7444 3.20586C21.2987 3.20124 25.8531 3.20124 30.4075 3.20586C31.7486 3.44369 32.9537 3.98031 34.0227 4.81573C35.2802 5.84378 36.0747 7.15643 36.4063 8.75367C36.4439 8.94477 36.4763 9.1367 36.5035 9.32961C36.5081 16.9649 36.5081 24.6003 36.5035 32.2355C36.4926 32.37 36.4464 32.4902 36.3647 32.5964C36.28 32.6902 36.1747 32.7446 36.049 32.7594C29.517 32.7641 22.9849 32.7641 16.453 32.7594C14.61 32.5513 12.9354 31.9129 11.4291 30.8443C10.1823 29.9354 9.21189 28.7939 8.51812 27.4198C7.93163 26.2381 7.55227 24.9891 7.38011 23.6727C7.30808 22.0717 7.54866 20.522 8.10177 19.0235C9.07783 16.5255 10.8184 14.7988 13.3234 13.8435C14.2816 13.4936 15.2692 13.26 16.2864 13.1426C17.0932 13.047 17.9028 13.0077 18.7151 13.0247C20.7643 15.0669 22.8113 17.1116 24.8562 19.1588C22.934 19.1589 21.0119 19.1636 19.0898 19.1727C18.2959 19.2552 17.5927 19.5489 16.9803 20.054C16.4299 20.5498 16.061 21.157 15.8736 21.8755C15.5969 22.8598 15.694 23.8035 16.165 24.7066C16.4615 25.2414 16.8697 25.6705 17.3897 25.9938C17.8374 26.265 18.3162 26.4617 18.8261 26.5837C21.7105 26.5871 24.5948 26.5883 27.4792 26.5871C27.4827 21.3966 27.4792 16.2062 27.4688 11.0158C27.4366 10.6856 27.3417 10.3756 27.1843 10.086C26.9237 9.6604 26.5432 9.43599 26.0428 9.41288Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.4306 3.22309C53.1075 3.22193 60.7845 3.22309 68.4614 3.22656C69.707 3.4045 70.8402 3.85554 71.8616 4.57968C73.0315 5.44477 73.8358 6.57237 74.2729 7.96249C74.454 8.55516 74.572 9.16122 74.6268 9.78053C74.6317 17.2423 74.6317 24.7042 74.6268 32.166C74.6046 32.3524 74.5276 32.5108 74.3944 32.6414C74.3153 32.7053 74.2251 32.7446 74.1237 32.7593C71.4314 32.764 68.739 32.764 66.0466 32.7593C65.8555 32.7374 65.7133 32.6437 65.6199 32.4783C65.5915 32.4215 65.5753 32.3613 65.5713 32.2979C65.569 25.3865 65.5667 18.4752 65.5644 11.5639C65.5439 11.1859 65.4421 10.832 65.2591 10.5022C64.9722 10.0493 64.5593 9.81217 64.0204 9.79094C59.3551 9.78629 54.6896 9.78629 50.0243 9.79094C49.6055 9.84194 49.2423 10.0108 48.9348 10.2975C48.6797 10.5591 48.5166 10.8702 48.4456 11.2308C48.4203 11.3592 48.4041 11.4887 48.3971 11.6194C48.3948 18.4983 48.3924 25.3773 48.3901 32.2562C48.3783 32.4147 48.3146 32.5477 48.1993 32.6552C48.1207 32.713 48.0328 32.7477 47.9356 32.7593C45.2478 32.7605 42.56 32.764 39.8724 32.7697C39.5731 32.7492 39.41 32.5896 39.3832 32.2909C39.3785 24.6117 39.3785 16.9324 39.3832 9.25316C39.4328 8.70553 39.5553 8.17587 39.751 7.66411C40.2047 6.52468 40.9183 5.5798 41.8917 4.82949C42.4633 4.399 43.0809 4.04511 43.7444 3.76781C44.0992 3.62563 44.46 3.49842 44.8269 3.38616C45.0275 3.3285 45.2287 3.27414 45.4306 3.22309Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5964 9.39893C15.7452 9.40358 20.894 9.40815 26.0428 9.4128C20.8917 9.41509 15.7405 9.4128 10.5894 9.40587C10.5903 9.40163 10.5926 9.39934 10.5964 9.39893Z"
        fill={props.stroke}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.9248 12.1745C55.6218 12.1733 58.3188 12.1745 61.0158 12.178C61.2805 12.2137 61.4319 12.3628 61.4703 12.6256C61.4715 19.1772 61.475 25.7289 61.4807 32.2805C61.4686 32.5702 61.3183 32.7298 61.0297 32.7593C58.3257 32.7605 55.6218 32.764 52.9179 32.7697C52.6392 32.7536 52.49 32.6056 52.4703 32.3256C52.4657 25.7566 52.4657 19.1876 52.4703 12.6186C52.509 12.3544 52.6605 12.2064 52.9248 12.1745Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default LogoMenuSvg;
