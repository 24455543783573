import React from 'react';

function UnlockedSvg() {
  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="94" height="94" rx="47" fill="white" />
      <mask
        id="mask0_3568_12883"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="31"
        y="26"
        width="34"
        height="42"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.9959 26C44.5554 26 41.7258 27.1423 39.8968 29.5012C38.4299 31.3931 37.7334 33.8684 37.7334 36.6575H40.6667C40.6667 34.3398 41.2443 32.5505 42.2149 31.2987C43.3541 29.8295 45.2178 28.9333 47.9959 28.9333C50.5134 28.9333 52.2926 29.7107 53.4515 30.9463C54.6233 32.1955 55.3334 34.0973 55.3334 36.6656V43.6H34.8C33.18 43.6 31.8667 44.9133 31.8667 46.5333V64.1333C31.8667 65.7534 33.18 67.0667 34.8 67.0667H61.2C62.8201 67.0667 64.1334 65.7534 64.1334 64.1333V46.5333C64.1334 44.9133 62.8201 43.6 61.2 43.6H58.2667V36.6656C58.2667 33.572 57.4057 30.8742 55.591 28.9395C53.7634 26.9911 51.1405 26 47.9959 26ZM34.8 46.5333H61.2V64.1333H34.8V46.5333Z"
          fill="#0891B2"
        />
      </mask>
      <g mask="url(#mask0_3568_12883)">
        <rect x="26" y="26" width="44" height="44" fill="#013042" />
      </g>
      <rect x="1" y="1" width="94" height="94" rx="47" stroke="#013042" strokeWidth="2" />
    </svg>
  );
}

export default UnlockedSvg;
