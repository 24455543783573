import React from 'react';

function DeleteSvg() {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_4724_16940"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="1"
        width="11"
        height="17"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.03548 1.33203C6.71676 1.33203 6.45839 1.63051 6.45839 1.9987C6.45839 2.36689 6.71676 2.66536 7.03548 2.66536H11.6522C11.9709 2.66536 12.2293 2.36689 12.2293 1.9987C12.2293 1.63051 11.9709 1.33203 11.6522 1.33203H7.03548ZM4.15002 4.66536C4.15002 4.29717 4.4084 3.9987 4.72712 3.9987H6.45839H12.2293H13.9606C14.2793 3.9987 14.5377 4.29717 14.5377 4.66536C14.5377 5.03355 14.2793 5.33203 13.9606 5.33203H13.3835V15.9987C13.3835 16.7351 12.8667 17.332 12.2293 17.332H6.45839C5.82095 17.332 5.30421 16.7351 5.30421 15.9987V5.33203L4.72712 5.33203C4.4084 5.33203 4.15002 5.03355 4.15002 4.66536ZM6.45839 5.33203H12.2293V15.9987H6.45839V5.33203Z"
          fill="#0891B2"
        />
      </mask>
      <g mask="url(#mask0_4724_16940)">
        <rect x="0.687378" width="17.3127" height="20" fill="#013042" />
      </g>
    </svg>
  );
}

export default DeleteSvg;
