import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    dataUser: {},
    authority: [],
    branch: ''
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.dataUser = action?.payload
        },
        setAuthority: (state, action) => {
            state.authority = action?.payload
        },
        setBranch: (state, action) => {
            state.branch = action?.payload
        },
        userLoggedOut: () => initialState,
    },
})

export const { setUser, setAuthority, setBranch } = userSlice.actions

export default userSlice.reducer