import React from 'react';

function MailSvg() {
  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="94" height="94" rx="47" fill="white" />
      <mask
        id="mask0_3568_12818"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="26"
        y="31"
        width="44"
        height="34"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.9333 31.8667C27.3133 31.8667 26 33.18 26 34.8V61.2C26 62.8201 27.3133 64.1334 28.9333 64.1334H67.0667C68.6867 64.1334 70 62.8201 70 61.2V34.8C70 33.18 68.6867 31.8667 67.0667 31.8667H28.9333ZM28.9333 34.8L67.0667 34.8V37.5132C66.8244 37.513 66.5791 37.5794 66.3594 37.7189L48 49.37L29.6406 37.7189C29.4209 37.5794 29.1756 37.513 28.9333 37.5132V34.8ZM28.9333 40.3967V61.2H67.0667V40.3967L48.7073 52.0479C48.2756 52.3219 47.7244 52.3219 47.2927 52.0479L28.9333 40.3967Z"
          fill="#0891B2"
        />
      </mask>
      <g mask="url(#mask0_3568_12818)">
        <rect x="26" y="26" width="44" height="44" fill="#013042" />
      </g>
      <rect x="1" y="1" width="94" height="94" rx="47" stroke="#013042" strokeWidth="2" />
    </svg>
  );
}

export default MailSvg;
