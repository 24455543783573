import ApiService from './ApiService';

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: '/auth/login',
    method: 'post',
    data,
  });
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: '/auth/register',
    method: 'post',
    data,
  });
}

export async function apiUserMe() {
  return ApiService.fetchData({
    url: '/auth/me',
    params: {
      relations: [
        'userRole.role.rolePermissions.permission',
        'userPermissions.permission',
        'unCheckuserPermissions.permission',
        'avatar',
        'employee'
      ].join()
    },
    method: 'get',
  });
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: '/auth/logout',
    method: 'post',
    data,
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: '/passwords/forgot-password',
    method: 'post',
    data,
  });
}

export async function apiResetPassword(data, resetToken) {
  return ApiService.fetchData({
    url: `/passwords/reset-password/${resetToken}`,
    method: 'post',
    data,
  });
}

export async function apiAccountActivation(data, token) {
  return ApiService.fetchData({
    url: `/base/users/set-activation/${token}`,
    method: 'post',
    data,
  });
}
