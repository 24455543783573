import React, { forwardRef, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Pagination, Select, Checkbox } from 'components/ui';
import TableRowSkeleton from './loaders/TableRowSkeleton';
import Loading from './Loading';
import { useTable, usePagination, useSortBy, useRowSelect } from 'react-table';
import DataNotFoundSvg from 'components/custom/svg/DataNotFoundSvg';
import classNames from 'classnames';

const { Tr, Th, Td, THead, TBody, Sorter, TFoot } = Table;

const IndeterminateCheckbox = forwardRef((props, ref) => {
  const { indeterminate, onChange, onCheckBoxChange, onIndeterminateCheckBoxChange, ...rest } = props;

  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  const handleChange = (e) => {
    onChange(e);
    onCheckBoxChange?.(e);
    onIndeterminateCheckBoxChange?.(e);
  };

  return <Checkbox className="mb-0" ref={resolvedRef} onChange={(_, e) => handleChange(e)} {...rest} />;
});

const DataTable = (props) => {
  const {
    skeletonAvatarColumns,
    columns,
    data,
    loading,
    onCheckBoxChange,
    onIndeterminateCheckBoxChange,
    onPaginationChange,
    onSelectChange,
    onSort,
    pageSizes,
    selectable,
    skeletonAvatarProps,
    pagingData,
    wrapClass,
    autoResetSelectedRows,
    showPagination,
    showLimitPerPage,
    manualSortBy = true,
    tableTotal,
  } = props;

  const { pageSize, pageIndex, total } = pagingData;

  const pageSizeOption = useMemo(
    () => pageSizes.map((number) => ({ value: number, label: `${number} / Page` })),
    [pageSizes],
  );

  const handleCheckBoxChange = (checked, row) => {
    if (!loading) {
      onCheckBoxChange?.(checked, row);
    }
  };

  const handleIndeterminateCheckBoxChange = (checked, rows) => {
    if (!loading) {
      onIndeterminateCheckBoxChange?.(checked, rows);
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: manualSortBy,
      autoResetSelectedRows,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (selectable) {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Header: (props) => (
              <div>
                <IndeterminateCheckbox
                  {...props.getToggleAllRowsSelectedProps()}
                  onIndeterminateCheckBoxChange={(e) => handleIndeterminateCheckBoxChange(e.target.checked, props.rows)}
                />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  onCheckBoxChange={(e) => handleCheckBoxChange(e.target.checked, row.original)}
                />
              </div>
            ),
            sortable: false,
          },
          ...columns,
        ]);
      }
    },
  );

  const handlePaginationChange = (page) => {
    if (!loading) {
      onPaginationChange?.(page);
    }
  };

  const handleSelectChange = (value) => {
    if (!loading) {
      onSelectChange?.(Number(value));
    }
  };

  const handleSort = (column) => {
    if (!loading) {
      const { id, isSortedDesc, toggleSortBy, clearSortBy } = column;
      const sortOrder = isSortedDesc ? 'desc' : 'asc';
      toggleSortBy(!isSortedDesc);
      onSort?.({ order: sortOrder, key: id }, { id, clearSortBy });
    }
  };

  const calculateTotals = (data, columns) => {
    return columns.map((col) => {
      if (col.summation && col.accessor && typeof data[0][col.accessor] === 'number') {
        return data.reduce((sum, row) => sum + row[col.accessor], 0);
      }
      return null;
    });
  };

  const totals = calculateTotals(data, columns);

  return (
    <Loading loading={loading && data.length !== 0} type="cover" className="flex flex-col flex-1">
      <div className="h-full">
        <Table
          {...getTableProps({
            wrapClass: wrapClass,
          })}
        >
          <THead className="!bg-transparent">
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <Th
                    className={`text-black text-sm font-bold font-['Sen'] leading-tight bg-second-600 ${
                      column.className || ''
                    } ${columnIndex === 0 ? 'rounded-tl-lg' : ''} ${
                      columnIndex === headerGroup.headers.length - 1 ? 'rounded-tr-lg' : ''
                    }`}
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.width,
                        width: column.width,
                        height: column.height,
                      },
                    })}
                    key={column.render('Header') + 'index'}
                  >
                    {column.render('Header') &&
                      (column.sortable ? (
                        <div
                          className={classNames('flex items-center cursor-pointer', column.headerClassName)}
                          onClick={() => handleSort(column)}
                        >
                          <span className="flex items-center text-sm font-bold text-black">
                            {column.render('Header')}
                          </span>
                          <span className="pl-[0.5rem] flex items-center">
                            <Sorter sort={column.isSortedDesc} />
                          </span>
                        </div>
                      ) : (
                        <div
                          className={classNames(
                            'flex items-center text-sm font-bold text-black',
                            column.headerClassName,
                          )}
                        >
                          {column.render('Header')}
                        </div>
                      ))}
                  </Th>
                ))}
              </Tr>
            ))}
          </THead>
          {loading && data.length === 0 ? (
            <TableRowSkeleton
              columns={columns.length}
              rows={pagingData.pageSize}
              avatarInColumns={skeletonAvatarColumns}
              avatarProps={skeletonAvatarProps}
            />
          ) : (
            <>
              <TBody {...getTableBodyProps()}>
                {!loading && page.length === 0 ? (
                  <Tr>
                    <Td colSpan={100}>
                      <div className="flex justify-center my-8 max-h-full">
                        <DataNotFoundSvg />
                      </div>
                    </Td>
                  </Tr>
                ) : (
                  page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <Tr
                        {...row.getRowProps({
                          style: {
                            height: row.height,
                          },
                        })}
                        key={i}
                      >
                        {row.cells.map((cell, u) => {
                          return (
                            <Td {...cell.getCellProps()} key={u}>
                              {cell.render('Cell')}{' '}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })
                )}
              </TBody>
              {tableTotal && (
                <TFoot>
                  <Tr>
                    {totals.map((total, index, arr) => {
                      if (index < arr.length - 1) {
                        // If it's not the last column, check if the next column has a summation value
                        if (arr[index + 1] !== null) {
                          return (
                            <Td key={index} colSpan={index + 1}>
                              TOTAL
                            </Td>
                          );
                        }
                        return null; // Return null for non-summation columns
                      } else {
                        return <Td key={index}>{total}</Td>;
                      }
                    })}
                  </Tr>
                </TFoot>
              )}
            </>
          )}
        </Table>
      </div>
      {showPagination ? (
        <div className="flex justify-end mt-4">
          <div className="mr-[1rem]">
            <Pagination pageSize={pageSize} currentPage={pageIndex} total={total} onChange={handlePaginationChange} />
          </div>
          {showLimitPerPage ? (
            <div className="ml-[1rem]" style={{ minWidth: 130 }}>
              <Select
                className="text-gray-700 !text-base"
                size="sm"
                menuPlacement="top"
                isSearchable={false}
                value={pageSizeOption.filter((option) => option.value === pageSize)}
                options={pageSizeOption}
                onChange={(option) => handleSelectChange(option.value)}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </Loading>
  );
};

DataTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  onCheckBoxChange: PropTypes.func,
  onIndeterminateCheckBoxChange: PropTypes.func,
  onPaginationChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  onSort: PropTypes.func,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  selectable: PropTypes.bool,
  skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
  skeletonAvatarProps: PropTypes.object,
  pagingData: PropTypes.shape({
    total: PropTypes.number,
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
  }),
  autoResetSelectedRows: PropTypes.bool,
  wrapClass: PropTypes.string,
  showPagination: PropTypes.bool,
  showLimitPerPage: PropTypes.bool,
  tableTotal: PropTypes.bool,
};

DataTable.defaultProps = {
  pageSizes: [10, 25, 50, 100],
  pagingData: {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
  },
  data: [],
  columns: [],
  wrapClass: '',
  selectable: false,
  loading: false,
  autoResetSelectedRows: true,
  showPagination: true,
  showLimitPerPage: true,
  tableTotal: false,
};

export default DataTable;
